import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { environment } from 'src/environments/environment';

// ANGULAR COMPONENT
import { HeaderInterceptor } from './interceptor/http.interceptor'
import { SharedModule } from './shared/shared.module';
import { FooterComponent } from './footer/footer.component';
import { HomeComponent } from './home/home.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { PromotionComponent } from './promotion/promotion.component';
import { PromotionDetailComponent } from './promotion-detail/promotion-detail.component';
import { StoreComponent } from './store/store.component';
import { ProductComponent } from './product/product.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { CartComponent } from './cart/cart.component';
import { VoucherPopupComponent } from './popup/voucher-popup/voucher-popup.component';
import { DatetimePopupComponent } from './popup/datetime-popup/datetime-popup.component';
import { StoreDetailComponent } from './store-detail/store-detail.component';
import { DialogPopupComponent } from './popup/dialog-popup/dialog-popup.component';
import { PaymentOptionPopupComponent } from './popup/payment-option-popup/payment-option-popup.component';
import { PaymentComponent } from './payment/payment.component';
import { QrPopupComponent } from './popup/qr-popup/qr-popup.component';
import { StorePopupComponent } from './popup/store-popup/store-popup.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PromotionPopupComponent } from './popup/promotion-popup/promotion-popup.component';

// ANGULAR MATERIAL
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatRadioModule } from '@angular/material/radio';
import { OwlModule } from 'ngx-owl-carousel';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { QRCodeModule } from 'angularx-qrcode';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';

// FIREBASE
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAnalyticsModule } from '@angular/fire/analytics';

import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { CommonModule } from '@angular/common';
import { AlertPopupComponent } from './popup/alert-popup/alert-popup.component';
import { StoreConfirmationPopupComponent } from './popup/store-confirmation-popup/store-confirmation-popup.component';
import { HeaderComponent } from './header/header.component';
import { QrVoucherPopupComponent } from './popup/qr-voucher-popup/qr-voucher-popup.component';
import { TermsComponent } from './terms/terms.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { TermsPopupComponent } from './popup/terms-popup/terms-popup.component';
import { SigninPopupComponent } from './popup/signin-popup/signin-popup.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { ForgotpasswordPopupComponent } from './popup/forgotpassword-popup/forgotpassword-popup.component';
import { CampaignPopupComponent } from './popup/campaign-popup/campaign-popup.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    RegisterComponent,
    LoginComponent,
    NotFoundComponent,
    ForgotPasswordComponent,
    HomeComponent,
    PromotionComponent,
    PromotionDetailComponent,
    StoreComponent,
    ProductComponent,
    ProductDetailComponent,
    CheckoutComponent,
    CartComponent,
    VoucherPopupComponent,
    DatetimePopupComponent,
    StoreDetailComponent,
    DialogPopupComponent,
    PaymentOptionPopupComponent,
    PaymentComponent,
    QrPopupComponent,
    StorePopupComponent,
    AboutUsComponent,
    AlertPopupComponent,
    StoreConfirmationPopupComponent,
    HeaderComponent,
    PromotionPopupComponent,
    QrVoucherPopupComponent,
    TermsComponent,
    TermsPopupComponent,
    SigninPopupComponent,
    PaymentSuccessComponent,
    ForgotpasswordPopupComponent,
    CampaignPopupComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    MatButtonModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatSelectModule,
    MatRadioModule,
    MatTabsModule,
    OwlModule,
    MatDialogModule,
    MatSnackBarModule,
    MatDatepickerModule,
    MatInputModule,
    MatNativeDateModule,
    QRCodeModule,
    MatCheckboxModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    AngularFireAnalyticsModule,
    SharedModule,
    MatBadgeModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    CommonModule,
    PdfViewerModule,
    MatExpansionModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true },
    { provide: MAT_DATE_LOCALE, useValue: 'en-au' }, // date format to dd/mm/yyyy (australia)
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

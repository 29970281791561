<button class="close" mat-icon-button (click)="onClose()">
  <mat-icon>close</mat-icon>
</button>

<h1 mat-dialog-title>Payment Options</h1>
<div mat-dialog-content>
  <div *ngIf="paymentOptions">
    <div *ngIf="user" class="row mb-5">
      <div class="profile-img col-3">
        <!-- <div class="thumb">
          <img [src]="user.avatar" alt="profile-image">
        </div> -->
        <img [src]="user.avatar" class="w-100 h-auto payment-profile-img">
      </div>
      <div class="col">
        <div>Available Points</div>
        <div>
          <span class="font-weight-medium f18 mr-3">{{user.points}} Points</span> 
          <span class="f14">{{user.pointvalue | currency}}</span>
        </div>
        <div *ngIf="store" class="payment-store">
          <span class="font-weight-bold">Selected Store: {{store.name}}</span>
        </div>
      </div>
    </div>

    <div class="mb-3 text-center" *ngIf="showRedeemInput">
      <p class="mb-0">Please enter a minimum of {{paymentOptions.partialPaymentButton[0].minPointsToDeduct}} points to a 
        maximum of {{paymentOptions.partialPaymentButton[0].maxPointsToDeduct}} points only.</p>
      <div class="mb-1">
        <label class="text-right d-block mb-0" for="redeemPoint"><small>Points</small></label>
        <input [ngModel]="redeemPoint" (ngModelChange)="onChangePoint($event)" id="redeemPoint" type="number" min="10" max="20" placeholder="Enter points to redeem">
        <small class="text-danger" *ngIf="pointTresholdError">Points entered does not meet min/max threshold</small>
      </div>
      
      <div class="mt-3">
        <label for="redeemBtn">Remaining for card payment is ${{redeemPoint == null? paymentOptions.fullPaymentButton : remainingBalance|number : '1.2-2'}}</label>
        <div class="d-flex gap-2">
          <button id="redeemBtn" [disabled]="pointTresholdError || redeemPoint == null" class="btn btn-confirmRedeemPoint" (click)="selectOption(remainingBalance, 'Pay with Points + Card',redeemPoint , 'redeem')">Confirm</button>
          <button id="redeemBtn" class="btn btn-cancelRedeemPoint" (click)="showRedeemInput = false;showRedeemBtn = true">Cancel</button>
        </div>
      </div>
    </div>

    <div *ngIf="paymentOptions.partialPaymentButton && showRedeemBtn" class="mb-3">
      <button class="btn btn-payoption partial-pay p-3" (click)="showRedeemInput = true;showRedeemBtn = false">
        <div class="font-weight-medium">Pay with Points + Card</div>
      </button>
    </div>

    <!-- <div *ngIf="paymentOptions.partialPaymentButton" class="mb-3">
      <button class="btn btn-payoption partial-pay"(click)="selectOption(paymentOptions.partialPaymentButton[0].partialPayAmount, 'Pay with Points + Card', 'redeem')">
        <div class="font-weight-medium">Pay with Points + Card</div>
        <div class="f14">{{paymentOptions.partialPaymentButton[0].pointsToDeduct}} Points + {{paymentOptions.partialPaymentButton[0].partialPayAmount | currency}}</div>
      </button>
    </div> -->
    <div *ngIf="paymentOptions.fullPaymentButton  && showRedeemBtn">
      <button class="btn btn-payoption full-pay" (click)="selectOption(paymentOptions.fullPaymentButton, 'Pay with Card', 'online')">
        <div class="font-weight-medium">Pay with Card</div>
        <div class="f14">{{paymentOptions.fullPaymentButton | currency }}</div>
      </button>
    </div>
    <!-- <div *ngIf="isSkipPayment">
      <button class="btn btn-payoption full-pay" (click)="selectOption(0, 'Pay with Card', 'online')">
        <div class="font-weight-medium">Pay with Card</div>
        <div class="f14">{{ 0 | currency }}</div>
      </button>
    </div> -->
  </div>

  <ng-template [ngIf]="errorMessage">
    <span>{{errorMessage}}</span>
  </ng-template>

  <ng-template [ngIf]="!errorMessage && !paymentOptions">
    <span>Loading ...</span>
  </ng-template>
</div>